import React, { useEffect,lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LazyWrapper from './components/LazyWrapper/LazyWrapper';
import { toast } from 'react-toastify';

/* Footer */
import Footer from './components/Footer/Footer';

/* Headers */
import Header from './components/Header/HeaderWithoutSignIn/Header';
import HeaderVirtualAssistant from './components/Header/HeaderVirtualAssistant/HeaderVirtualAssistant';
import HeaderLawyer from './components/Header/HeaderLawyer/HeaderLawyer';
import HeaderPhotographer from './components/Header/HeaderPhotographer/HeaderPhotographer';
import HeaderBuyer from './components/Header/HeaderBuyer/HeaderBuyer';
import HeaderSeller from './components/Header/HeaderSeller/HeaderSeller';

/* Helper */
import activePersonaType from './helpers/activePersonaType';
import { routeLoader } from './components/LazyWrapper/routerLoader';
import { getLocalStorageItem } from './config/localStorageEncryption';
import ZohoMiniApp from './pages/ZohoMiniApp/ZohoMiniApp';
import { useDispatch } from 'react-redux';
import { listServiceRequests } from './features/common/serviceRequestSlice';
import { setUploadProgress } from './features/common/progressSlice';
import { joinRoom, socket } from './config/socketIo';
import { getUserSocketIoRoomId } from './hooks/useTranslator';
import { messageNotifications, notifications } from './features/user/authSlice';
import Cookie from "js-cookie";

/* global_pages */
const Login =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/Login/Login')));
const ForgotPassword =lazy(()=> routeLoader(()=> import('./pages/ForgotPassword/ForgotPassword')));
const ConfirmNewPassword =lazy(()=> routeLoader(()=> import('./pages/ConfirmNewPassword/ConfirmNewPassword')));

/* pages without sign in */
const Home =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/Home/Home')));
const Registration =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/Registration/Registration')));
const RegistrationAbout =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/Registration/RegistrationAbout/RegistrationAbout')));
const RegistrationSuccessful =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/Registration/RegistrationSuccessful/RegistrationSuccessful')));
const EmailVerificationSuccessfull =lazy(()=> routeLoader(()=> import("./pages/WithoutLogin/EmailVerficationSuccessfull/SuccessfullVerfication")));
const EmailUpdateRequestSuccess =lazy(()=> routeLoader(()=> import("./pages/WithoutLogin/EmailUpdateRequestSuccess/EmailUpdateRequestSuccess")));
const SearchProperty =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/SearchPages/SearchProperties/SearchProperty')));
const SearchPropertyDetails =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/SearchPages/SearchPropertyDetails/SearchPropertyDetails')));
const CommonThirdParty =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/CommonThirdParty/CommonThirdParty')));
const CommonLawyerProfile =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/CommonThirdPartyProfiles/LawyerProfile/LawyerProfile')));
const CommonPhotographerProfile =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/CommonThirdPartyProfiles/PhotographerProfile/PhotographerProfile')));
const CommonVirtualAssistantProfile =lazy(()=> routeLoader(()=> import('./pages/WithoutLogin/CommonThirdPartyProfiles/VirtualAssistantProfile/VirtualAssistantProfile')));

// secondary pages
const About =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/About/About')));
const Contact =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/Contact/Contact')));
const HowToBuyProperty =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/HowToBuyProperty/HowToBuyProperty')));
const HowToSellProperty =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/HowToSellProperty/HowToSellProperty')));
const GeneralTerms =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/GeneralTerms/GeneralTerms')));
const FrequentlyQuastion =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/FrequentlyQuastion/FrequentlyQuastion')));
const PrivacyPolicy =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/PrivacyPolicy/PrivacyPolicy')));
const Cookies =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/Cookies/Cookies')));

/* pages virtual assistant */
const Education =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/Education/Education')));
const Exam =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/Exam/Exam')));
const ExamTest =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/ExamTest/ExamTest')));
const SuccessTest =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/ExamTest/SuccessTest/SuccessTest')));
const WrongTest =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/ExamTest/WrongTest/WrongTest')));
const HomeVirtualAssistant =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/Home/Home')));
const DashboardVirtualAssistant =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/Dashboard/Dashboard')));
const MyProfileVirtualAssistant =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/MyProfile/MyProfile')));
const PublicProfileVirtualAssistant =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/PublicProfile/PublicProfile')));
const PublicProfileEditVirtualAssistant =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/PublicProfileEdit/PublicProfileEdit')));
const VipProfileBidVirtualAssistant =lazy(()=> routeLoader(()=> import('./pages/VirtualAssistant/VipProfile/VipProfileBid/VipProfileBid')));

/* pages lawyer */
const HomeLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/Home/HomeLawyer')));
const DashboardLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/Dashboard/Dashboard')));
const MyProfileLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/MyProfile/MyProfile')));
const PublicProfileLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/PublicProfileLawyer/PublicProfileLawyer')));
const PublicProfileLawyerEdit =lazy(()=> routeLoader(()=> import('./pages/Lawyer/PublicProfileLawyerEdit/PublicProfileLawyerEdit')));
const MultiProfileLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/MultiProfile/MultiProfile')));
const VipProfileLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/VipProfile/VipProfile')));
const VipProfileBidLawyer =lazy(()=> routeLoader(()=> import('./pages/Lawyer/VipProfile/VipProfileBid/VipProfileBid')));

/* pages photographer */
const HomePhotographer =lazy(()=> routeLoader(()=> import('./pages/Photographer/Home/Home')));
const RegistrationPhotographer =lazy(()=> routeLoader(()=> import('./pages/Photographer/Registration/Registration')));
const MyProfilePhotographer =lazy(()=> routeLoader(()=> import('./pages/Photographer/MyProfile/MyProfile')));
const PublicProfilePhotographer =lazy(()=> routeLoader(()=> import('./pages/Photographer/PublicProfilePhotographer/PublicProfilePhotographer')));
const PublicProfilePhotographerEdit =lazy(()=> routeLoader(()=> import('./pages/Photographer/PublicProfilePhotographerEdit/PublicProfilePhotographerEdit')));
const MultiProfile =lazy(()=> routeLoader(()=> import('./pages/Photographer/MultiProfile/MultiProfile')));
const VipProfile =lazy(()=> routeLoader(()=> import('./pages/Photographer/VipProfile/VipProfile')));
const VipProfileBid =lazy(()=> routeLoader(()=> import('./pages/Photographer/VipProfile/VipProfileBid/VipProfileBid')));
const PhotographerDashboard =lazy(()=> routeLoader(()=> import('./pages/Photographer/Dashboard/Dashboard')));
const RegistrationSuccess =lazy(()=> routeLoader(()=> import('./pages/Photographer/Registration/RegistrationSuccess/RegistrationSuccess')));

/* Pages Buyer */
const HomeBuyer =lazy(()=> routeLoader(()=> import('./pages/Buyer/Home/Home')));
const MyProfileBuyer =lazy(()=> routeLoader(()=> import('./pages/Buyer/MyProfile/MyProfile')));
const ChoosenBank =lazy(()=> routeLoader(()=> import('./pages/Buyer/ChooseBank/ChoosenBank')));
const Dashboard =lazy(()=> routeLoader(()=> import('./pages/Buyer/Dashboard/Dashboard')));
const Questioneery =lazy(()=> routeLoader(()=> import('./pages/Buyer/Questionnaire/Questionnaire')));
const PublicProfile =lazy(()=> routeLoader(()=> import('./pages/Buyer/PublicProfile/PublicProfile')));

/* pages seller */
const HomeSeller =lazy(()=> routeLoader(()=> import('./pages/Seller/HomePage/Homepage')));
const VisualsPage =lazy(()=> routeLoader(()=> import('./pages/Seller/VisualsPage/VisualsPage')));
const InformationPage =lazy(()=> routeLoader(()=> import('./pages/Seller/InformationPage/InformationPage')));
const PricePage =lazy(()=> routeLoader(()=> import('./pages/Seller/PricePage')));
const Documentspage =lazy(()=> routeLoader(()=> import('./pages/Seller/DocumentsPage')));
const DashboardSeller =lazy(()=> routeLoader(()=> import('./pages/Seller/Dashboard/Dashboard')));
const MyAdds =lazy(()=> routeLoader(()=> import('./pages/Seller/MyAdds/MyAdds')));
const SellerPublicProfile =lazy(()=> routeLoader(()=> import('./pages/Seller/PublicProfile/Profile')));
const SellerProfile =lazy(()=> routeLoader(()=> import('./pages/Seller/SellerProfile/SellerProfile')));
const QuestioneerySeller =lazy(()=> routeLoader(()=> import('./pages/Seller/Questionnaire/Questionnaire')));
const Preview =lazy(()=> routeLoader(()=> import('./pages/VirtualTour/components/Preview')));
const Pricing =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/Pricing/Pricing')));

/* pages blog */
const Blog =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/Blog/Blog')));
const IndividualBlog =lazy(()=> routeLoader(()=> import('./pages/SecondaryPages/IndividualBlog/IndividualBlog')));

const RoutesPage = () => {
    const { t } = useTranslation();

    const activePersonaDetails = getLocalStorageItem("activePersonaDetails") ? getLocalStorageItem("activePersonaDetails") : '';

    const location = useLocation();
    const navigate = useNavigate();
    
    const dispatch=useDispatch();

    const isEmptyPersona = activePersonaType('is_empty_persona');
    const isVitualAssistantPersona = activePersonaType('is_virtual_assistant');
    const isLawyerPersona = activePersonaType('is_lawyer');
    const isPhotographerPersona = activePersonaType('is_photographer');
    const isBuyerPersona = activePersonaType('is_buyer');
    const isSellerPersona = activePersonaType('is_seller');

    const userId = activePersonaDetails?.userId;

    useEffect(() => {
        if (isVitualAssistantPersona && !activePersonaDetails?.isCertified) {
            const locationPathname = location?.pathname;
            const isEducation = locationPathname === '/virtual' || locationPathname === '/virtual/education' ||
                locationPathname === '/virtual/education-exam' || locationPathname === '/virtual/education-test' ||
                locationPathname === '/virtual/success-test' || locationPathname === '/virtual/wrong-test';

            if (!isEducation) {
                navigate('/virtual/education');
                toast.dismiss();
                toast.error(`${t('Премини успешно теста, за да използваш своя профил')}`);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname]);

    /* Handle logout */
    const handleLogout = () => {
            localStorage.removeItem("activePersonaDetails");
            localStorage.removeItem("authenticatedUser");
            localStorage.removeItem("activePersona");
            localStorage.removeItem("user");
            localStorage.removeItem("currentTokenNotification");
            localStorage.removeItem("virtualAssistantRefLink");
            Cookie.remove('userToken');
            Cookie.remove("activerPersona")
            navigate("/login");
    };

    useEffect(() => {
        if (!isEmptyPersona) {
          let userSocketIoRoomId = getUserSocketIoRoomId();
          socket.connect();
          joinRoom(userSocketIoRoomId);
          socket.on("update available", () => {
			    document.getElementById("loading-indicator").classList.add("open");
                dispatch(
                    setUploadProgress({
                      isProgressBarVisible: true,
                      type:"updateAvailable",
                    })
                  );
                if(isLawyerPersona){
                    const providerId = activePersonaDetails?.lawyerPersonaId;
                    dispatch(listServiceRequests({ providerId, serviceType: "legal" })).finally(()=>{
                        document.getElementById("loading-indicator").classList.remove("open");
                        dispatch(
                            setUploadProgress({
                              isProgressBarVisible: false,
                              type: "",
                            })
                          );
                    })

                }
                else if(isPhotographerPersona){
                    const providerId = activePersonaDetails?.photographerPersonaId;
                    dispatch(listServiceRequests({ providerId, serviceType: "photography" })).finally(()=>{
                        document.getElementById("loading-indicator").classList.remove("open");
                        dispatch(
                            setUploadProgress({
                              isProgressBarVisible: false,
                              type: "",
                            })
                          );
                    })
                }
                else if(isVitualAssistantPersona){
                    const providerId = activePersonaDetails?.virtualAssistantId;
                    dispatch(listServiceRequests({ providerId, serviceType: "assistance" })).finally(()=>{
                        document.getElementById("loading-indicator").classList.remove("open");
                        dispatch(
                            setUploadProgress({
                              isProgressBarVisible: false,
                              type: "",
                            })
                          );
                    })
                }
                if(isBuyerPersona){
                    const customerId=activePersonaDetails?.buyerPersonaId
                    dispatch(listServiceRequests({ customerId, customerType: 'buyer' })).finally(()=>{
                        document.getElementById("loading-indicator").classList.remove("open");
                        dispatch(
                            setUploadProgress({
                              isProgressBarVisible: false,
                              type: "",
                            })
                          );
                    })
                }
                if(isSellerPersona){
                    const customerId=activePersonaDetails?.sellerPersonaId
                    dispatch(listServiceRequests({ customerId, customerType: 'seller' })).finally(()=>{
                        document.getElementById("loading-indicator").classList.remove("open");
                        dispatch(
                            setUploadProgress({
                              isProgressBarVisible: false,
                              type: "",
                            })
                          );
                    })
                }
                dispatch(notifications())
          });
          socket.on("new message notification",()=>{
            dispatch(messageNotifications({ filters: { notificationType: "atomChatNewMessage" } }));
          })
          socket.on("Account_restricted",(message)=>{
            handleLogout()
            toast.error(message);
          })
        }
    
        socket.on("disconnect", () => {
          socket.disconnect();
        });
        return () => {
          socket.disconnect(); // Disconnect the socket when the component unmounts
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    return (
        <>
            <>
                {
                    location?.pathname !== "/login" && location?.pathname !== "/registration-about" && location?.pathname !== "/registration"
                        ?   isEmptyPersona 
                                ?   <Header /> 
                                :   null
                        :   null
                }
                {isVitualAssistantPersona ? <HeaderVirtualAssistant /> : null}
                {isLawyerPersona ? <HeaderLawyer /> : null}
                {isPhotographerPersona ? <HeaderPhotographer /> : null}
                {isBuyerPersona ? <HeaderBuyer /> : null}
                {isSellerPersona ? <HeaderSeller /> : null}
            </>

            <Routes>
                <Route path="/about" element={<LazyWrapper><About /> </LazyWrapper>} />
                <Route path="/pricing" element={<LazyWrapper> <Pricing /> </LazyWrapper>} />
                <Route path="/contact" element={<LazyWrapper> <Contact /> </LazyWrapper>} />
                <Route path="/how-to-buy" element={<LazyWrapper> <HowToBuyProperty /> </LazyWrapper>} />
                <Route path="/how-to-sell" element={<LazyWrapper> <HowToSellProperty /> </LazyWrapper>} />
                <Route path="/general-terms" element={<LazyWrapper> <GeneralTerms /> </LazyWrapper>} />
                <Route path="/privacy-policy" element={<LazyWrapper> <PrivacyPolicy /> </LazyWrapper>} />
                <Route path="/cookies" element={<LazyWrapper><Cookies /> </LazyWrapper>} />
                <Route path="/frequently-quastion" element={<LazyWrapper> <FrequentlyQuastion /> </LazyWrapper>} />
                <Route path="/seller/public-profile" element={<LazyWrapper> <SellerPublicProfile /> </LazyWrapper>} />
                <Route path="/confirm-new-password" element={<LazyWrapper> <ConfirmNewPassword /> </LazyWrapper>} />
                <Route path="/email/verification" element={<LazyWrapper> <EmailVerificationSuccessfull /> </LazyWrapper>} />
                <Route path="/email/update" element={<LazyWrapper> <EmailUpdateRequestSuccess isOldEmailRequest/> </LazyWrapper>} />
                <Route path="/email/new-email-verification" element={<LazyWrapper> <EmailUpdateRequestSuccess /> </LazyWrapper>} />
                <Route path="/buyer/public-profile" element={<LazyWrapper> <PublicProfile /> </LazyWrapper>} />
                <Route path="/blog" element={<LazyWrapper> <Blog /> </LazyWrapper>} />
                <Route path="/blog/:slug" element={<LazyWrapper> <IndividualBlog /> </LazyWrapper>} />
                <Route path="/zoho-mini-app" element={<LazyWrapper> <ZohoMiniApp /> </LazyWrapper>} />
                
                {
                    isVitualAssistantPersona && !activePersonaDetails?.isCertified
                        ? null
                        : <>
                            <Route path="/search-properties" element={<LazyWrapper> <SearchProperty /> </LazyWrapper>} />
                            <Route path="/property-details" element={<LazyWrapper> <SearchPropertyDetails /> </LazyWrapper>} />
                            <Route path="/third-party" element={<LazyWrapper> <CommonThirdParty /> </LazyWrapper>} />
                            <Route path="/third-party/lawyer-profile/:personaId" element={<LazyWrapper> <CommonLawyerProfile /> </LazyWrapper>} />
                            <Route path="/third-party/photographer-profile/:personaId" element={<LazyWrapper> <CommonPhotographerProfile /> </LazyWrapper>} />
                            <Route path="/third-party/virtual-assistant-profile/:personaId" element={<LazyWrapper> <CommonVirtualAssistantProfile /> </LazyWrapper>} />
                        </>
                }

                {/* WITHOUT LOGIN PAGES */}
                {isEmptyPersona
                    ? <>
                        <Route path="/" element={<LazyWrapper> <Home /> </LazyWrapper>} />
                        <Route path="/home" element={<LazyWrapper> <Home /> </LazyWrapper>} />
                        <Route path="/virtual-assistants" element={<LazyWrapper> <Home /> </LazyWrapper>} />
                        <Route path="/forgot-password" element={<LazyWrapper> <ForgotPassword /> </LazyWrapper>} />
                        <Route path="/registration" element={<LazyWrapper> <Registration /> </LazyWrapper>} />
                        <Route path="/registration-about" element={<LazyWrapper> <RegistrationAbout /> </LazyWrapper>} />
                        <Route path="/registration-success" element={<LazyWrapper> <RegistrationSuccessful /> </LazyWrapper>} />
                        <Route path="/login" element={<LazyWrapper> <Login /> </LazyWrapper>} />
                    </>
                    : null
                }

                {/* VIRTUAL ASSISTANT PAGES */}
                {isVitualAssistantPersona
                    ? <>
                        <Route path="/virtual" element={<LazyWrapper> <HomeVirtualAssistant /> </LazyWrapper>} />
                        <Route path="/virtual/education" element={<LazyWrapper> <Education /> </LazyWrapper>} />
                        <Route path="/virtual/education-exam" element={<LazyWrapper> <Exam /> </LazyWrapper>} />
                        <Route path="/virtual/education-test" element={<LazyWrapper> <ExamTest /> </LazyWrapper>} />
                        <Route path="/virtual/success-test" element={<LazyWrapper> <SuccessTest /> </LazyWrapper>} />
                        <Route path="/virtual/wrong-test" element={<LazyWrapper> <WrongTest /> </LazyWrapper>} />
                        <Route path="/virtual/dashboard" element={<LazyWrapper> <DashboardVirtualAssistant /> </LazyWrapper>} />
                        <Route path="/virtual/my-profile/:virtualAssistantId" element={<LazyWrapper> <MyProfileVirtualAssistant /> </LazyWrapper>} />
                        <Route path="/virtual/my-profile/public/:virtualAssistantId" element={<LazyWrapper> <PublicProfileVirtualAssistant /> </LazyWrapper>} />
                        <Route path="/virtual/my-profile/public/update/:virtualAssistantId" element={<LazyWrapper> <PublicProfileEditVirtualAssistant /> </LazyWrapper>} />
                        <Route path="/virtual/vip-profile/bid" element={<LazyWrapper> <VipProfileBidVirtualAssistant /> </LazyWrapper>} />
                    </>
                    : null
                }

                {/* LAWYER PAGES */}
                {isLawyerPersona
                    ? <>
                        <Route path="/lawyer" element={<LazyWrapper> <HomeLawyer /> </LazyWrapper>} />
                        <Route path="/lawyer/dashboard" element={<LazyWrapper> <DashboardLawyer /> </LazyWrapper>} />
                        <Route path="/lawyer/profile/:lawyerPersonaId" element={<LazyWrapper> <MyProfileLawyer /> </LazyWrapper>} />
                        <Route path="/lawyer/profile/public/:lawyerPersonaId" element={<LazyWrapper> <PublicProfileLawyer /> </LazyWrapper>} />
                        <Route path="/lawyer/profile/public/update/:lawyerPersonaId" element={<LazyWrapper> <PublicProfileLawyerEdit /> </LazyWrapper>} />
                        <Route path="/lawyer-multi-profile" element={<LazyWrapper> <MultiProfileLawyer /> </LazyWrapper>} />
                        <Route path="/lawyer-vip-profile" element={<LazyWrapper> <VipProfileLawyer /> </LazyWrapper>} />
                        <Route path="/lawyer-vip-profile/bid" element={<LazyWrapper> <VipProfileBidLawyer /> </LazyWrapper>} />
                    </>
                    : null
                }

                {/* PHOTOGRAPHER PAGES */}
                {isPhotographerPersona
                    ? <>
                        <Route path="/photographer" element={<LazyWrapper> <HomePhotographer /> </LazyWrapper>} />
                        <Route path="/photographer-registration" element={<LazyWrapper> <RegistrationPhotographer /> </LazyWrapper>} />
                        <Route path="/photographer-registration-success" element={<LazyWrapper> <RegistrationSuccess /> </LazyWrapper>} />
                        <Route path="/photographer/dashboard" element={<LazyWrapper> <PhotographerDashboard /> </LazyWrapper>} />
                        <Route path="/photographer/my-profile/:photographerPersonaId" element={<LazyWrapper> <MyProfilePhotographer /> </LazyWrapper>} />
                        <Route path="/photographer/my-profile/public/:photographerPersonaId" element={<LazyWrapper> <PublicProfilePhotographer /> </LazyWrapper>} />
                        <Route path="/photographer/my-profile/public/update/:photographerPersonaId" element={<LazyWrapper> <PublicProfilePhotographerEdit /> </LazyWrapper>} />
                        <Route path="/photographer-multi-profile" element={<LazyWrapper> <MultiProfile /> </LazyWrapper>} />
                        <Route path="/photographer-vip-profile" element={<LazyWrapper> <VipProfile /> </LazyWrapper>} />
                        <Route path="/photographer-vip-profile/bid" element={<LazyWrapper> <VipProfileBid /> </LazyWrapper>} />
                    </>
                    : null
                }

                {/* BUYER PAGES */}
                {isBuyerPersona
                    ? <>
                        <Route path="/buyer" element={<LazyWrapper> <HomeBuyer /> </LazyWrapper>} />
                        <Route path="/buyer/buyer-my-profile" element={<LazyWrapper> <MyProfileBuyer /> </LazyWrapper>} />
                        <Route path="/buyer/choose-bank" element={<LazyWrapper> <ChoosenBank /> </LazyWrapper>} />
                        <Route path="/buyer/dashboard" element={<LazyWrapper> <Dashboard /> </LazyWrapper>} />
                        <Route path="/buyer/questioneery" element={<LazyWrapper> <Questioneery /> </LazyWrapper>} />
                    </>
                    : null
                }

                {/* SELLER PAGES */}
                {isSellerPersona
                    ? <>
                        <Route path="/seller" element={<LazyWrapper> <HomeSeller /> </LazyWrapper>} />
                        <Route path="/seller/post-an-add/information" element={<LazyWrapper> <InformationPage /> </LazyWrapper>} />
                        <Route path="/seller/post-an-add/visuals" element={<LazyWrapper> <VisualsPage /> </LazyWrapper>} />
                        <Route path="/seller/post-an-add/price" element={<LazyWrapper> <PricePage /> </LazyWrapper>} />
                        <Route path="/seller/post-an-add/documents" element={<LazyWrapper> <Documentspage /> </LazyWrapper>} />
                        <Route path="/seller/dashboard" element={<LazyWrapper> <DashboardSeller /> </LazyWrapper>} />
                        <Route path="/seller/my-adds" element={<LazyWrapper> <MyAdds /> </LazyWrapper>} />
                        <Route path="/seller/profile" element={<LazyWrapper> <SellerProfile /> </LazyWrapper>} />
                        <Route path="/seller/questioneery" element={<LazyWrapper> <QuestioneerySeller /> </LazyWrapper>} />
                    </>
                    : null
                }

                {/* VIRTUAL TOUR PAGES */}
                {
                    !isEmptyPersona && (
                        <>
                            <Route path='/virtual-tour/:id' element={<LazyWrapper> <Preview fullWidth={true} /> </LazyWrapper>} />
                        </>
                    )
                }
            </Routes>

            {
                ["/virtual/education-test"].some(str => window.location.pathname.includes(str)) 
                    ?   null 
                    :   location?.pathname !== "/login" && location?.pathname !== "/registration-about" && location?.pathname !== "/registration"
                            ?   <Footer />
                            :   null
            }
        </>)
};

export default RoutesPage;