import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./AddToHomeScreenBanner.css";
import { Dialog, DialogContent } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import Images from "../../../assets/uploaded_images.json"
const CloseModal = Images["close-modal.svg"];

function AddToHomeScreenBanner({ setShowFirebaseBanner }) {
    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="addToHomeScreenBanner">
            <div className="main-banner-container">
                <h6 className="banner-content"> {t("Добави известия")} </h6>

                <div className="showTips" onClick={() => setOpenModal(true)}> {t("Добави")} </div>

                <div className="close-button" onClick={() => setShowFirebaseBanner(false)}>
                    <CloseIcon />
                </div>

                <Dialog
                    disableScrollLock={true}
                    open={openModal}
                    fullWidth="md"
                    maxWidth="md"
                    PaperProps={{
                        style: { background: "#FFFFFF", boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.25)", borderRadius: "10px", padding: 15 },
                    }}
                    onClose={() => setOpenModal(false)}
                >
                    <DialogContent className="tips-content">
                        <div className="cancel-button">
                            <img loading="lazy" src={CloseModal} className="close-icon" alt="" onClick={() => setOpenModal(false)} />
                        </div>

                        <p className="sub-title"> {t("Добави известия")} </p>

                        <ol>
                            <li> {t("Отворете менюто Споделяне")} </li>
                            <li> {t("Докоснете бутона `Добавяне на начален екран`")} </li>
                        </ol>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}

export default AddToHomeScreenBanner;